import { Component, OnInit, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import { MarcaService } from '../../services/marca.service';
import { first } from 'rxjs/operators';
import { Marca, Modelo, Producto } from 'src/app/global/model/Model';
import { ModeloService } from '../../services/modelo.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogComponent } from 'src/app/global/dialog/dialog.component';
import { ProductoService } from '../../services/producto.service';

@Component({
  selector: 'app-busca-producto',
  templateUrl: './busca-producto.component.html',
  styleUrls: ['./busca-producto.component.css']
})
export class BuscaProductoComponent implements OnInit{
  onDelete(id: number) {
    throw new Error("Method not implemented.");
  }
  @Input() parent:string;
  // @Input() Producto: Producto;
  @Output() addItem: EventEmitter<any> = new EventEmitter<any>();
  ListaMarca: Marca[] = [];
  ListaModelo: Modelo[] = [];
  ListaProducto: any[] = [];

  Producto: Producto;

  Marca = new Marca();
  Modelo = new Modelo();
  Busqueda: Producto = new Producto();
  ProductoSeleccionado: Producto = new Producto('recepcion');

  TipoMovimiento: string;

  dialogConfig = new MatDialogConfig();
  Nombre: string = "";
  Titulo: string;

  constructor(private marca: MarcaService,
    private modelo: ModeloService,
    private producto: ProductoService,
    public dialog: MatDialog) {
     }

  ngOnInit() {
    this.getMarca();
      
    switch(this.parent){
      case 'Recepcion':{
        this.Titulo = 'Búsqueda de Producto a Recepcionar';
        break;
      }
      case 'Egreso':{
        this.Titulo = 'Búsqueda de Producto a Egresar';
        break;
      }
      case 'Venta':{
        this.Titulo = 'Búsqueda de Productos a Vender';
        break;
      }
    }
  }

  onEdit(producto: Producto){
    if (producto != undefined) {
          (<HTMLSelectElement>document.getElementById(this.parent + "Container").getElementsByTagName("select")["selectMarca"]).value = producto.IdMarca.toString();
          let promise = new Promise((resolve, reject) => { resolve(this.onMarcaChange(producto.IdMarca )) });
          Promise.all([promise]).then(() => {
            setTimeout(() => {
              (<HTMLSelectElement>document.getElementById(this.parent + "Container").getElementsByTagName("select")["selectModelo"]).value = producto.IdModelo.toString();
            },
              200);
          });
        }
    this.Nombre = producto.Nombre;
    this.ProductoSeleccionado = producto;
  }

  getMarca() {
    this.marca.getAll()
      .pipe(first())
      .subscribe(items => {
        this.ListaMarca = items.Registros;
        setTimeout(() => {
              (<HTMLSelectElement>document.getElementById(this.parent + "Container").getElementsByTagName("select")["selectMarca"]).value = "-1";
              (<HTMLSelectElement>document.getElementById(this.parent + "Container").getElementsByTagName("select")["selectModelo"]).value = "-1";
        },
          50);
      });
  }

  onMarcaChange(id: number): any {
    this.Busqueda.IdMarca = id;
    this.modelo.getPorMarca(id)
      .pipe(first())
      .subscribe(items => {
        if (items.Registros.length === 0) {
          this.dialogConfig.data = {
            Titulo: 'No se encontraron registros!',
            Mensaje: 'No hay marcas para mostrar!',
            Salir: false
          };
          this.dialog.open(DialogComponent, this.dialogConfig);
        } else {
          setTimeout(() => {
                (<HTMLSelectElement>document.getElementById(this.parent + "Container").getElementsByTagName("select")["selectModelo"]).value = "-1";
          },
            50);
        }
        this.ListaModelo = items.Registros;
      });
      return true;
  }

  onModeloChange(id: number) {
    this.Busqueda.IdModelo = id;
  }

  Find() {
    var palabras = this.Nombre.trim().split(' ');
    if (palabras.length >= 10) return;
    this.Busqueda.Nombre = this.Nombre.replace(' ', '|');

    this.Busqueda.isVenta = true;
    this.producto.find(this.Busqueda)
      .pipe(first())
      .subscribe(items => {
        this.ListaProducto = items.Registros;
        document.getElementById(this.parent + "Container").getElementsByTagName("input")["NombreItem"].focus();
      }, error => {
        console.log(error);
      });
  }

  onAgregar(producto:any){
    
    if( this.ProductoSeleccionado.Id == 0 ||
        this.ProductoSeleccionado.PrecioCompra == 0 ||
        this.ProductoSeleccionado.PrecioVenta == 0 ||
        this.ProductoSeleccionado.Cantidad == 0) return;
    this.addItem.emit(this.ProductoSeleccionado); 
    this.ProductoSeleccionado = new Producto('recepcion');
    (<HTMLSelectElement>document.getElementById(this.parent + "Container").getElementsByTagName("select")["selectMarca"]).value = "-1";
    this.ListaModelo = [];
    this.ListaProducto = [];
    this.Nombre = "";
    this.Busqueda = new Producto();
  }

  onSelect(producto: any) {
    this.ProductoSeleccionado = this.ListaProducto.find(o => o.Nombre === this.Nombre);
    (<HTMLSelectElement>document.getElementById(this.parent + "Container").getElementsByTagName("select")["selectMarca"]).value = this.ProductoSeleccionado.IdMarca.toString();
    let promise = new Promise((resolve, reject) => { resolve(this.onMarcaChange(this.ProductoSeleccionado.IdMarca)) });
    Promise.all([promise]).then(() => {
      setTimeout(() => {
      (<HTMLSelectElement>document.getElementById(this.parent + "Container").getElementsByTagName("select")["selectModelo"]).value = this.ProductoSeleccionado.IdModelo.toString();
      },
        200);
    });
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Usuario } from '../../global/model/Model';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

import * as Global from '../../global/global';
import { Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { DialogComponent } from 'src/app/global/dialog/dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<Usuario>;
  public currentUser: Observable<Usuario>;
  dialogConfig = new MatDialogConfig();

  constructor(private http: HttpClient,
              private router: Router,
              public dialog: MatDialog) {
      this.currentUserSubject = new BehaviorSubject<Usuario>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Usuario {
      return this.currentUserSubject.value;
  }

  login(login : Usuario) {
      return this.http.post<Usuario>(`${Global.apiUrl}users/login.php`, login, Global.httpPostOptions)
      .pipe(map(usuario => {
            
              // login successful if there's a jwt token in the response
              if (usuario && usuario.Token) {
                  // store user details and jwt token in local storage to keep user logged in between page refreshes
                  localStorage.setItem('currentUser', JSON.stringify(usuario));
                  this.currentUserSubject.next(usuario);
              }

              return usuario;
          }, err => {
            console.log(err);
            
          }));
  }

  logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);
      this.router.navigate(['/']);
  }

  cambiar(login: Usuario){
    return this.http.post<any>(`${Global.apiUrl}login/cambiar-pass`, login, Global.httpPostOptions)
      .pipe(map(usuario => {
              return usuario;
          }));
  }

  refreshToken() {
    console.log('auth refresh');
    let payload = {'token': this.currentUserValue.Token, 'refreshToken': this.currentUserValue.RefreshToken}
    console.log(payload);
    
    

  console.log('saltó');
  

  return this.http.post<Usuario>(`${Global.apiUrl}/login/refresh`, payload, Global.httpPostOptions)
  .pipe(map(usuario => {
      console.log('respuesta');
      
    // login successful if there's a jwt token in the response
    if (usuario && usuario.Token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(usuario));
        this.currentUserSubject.next(usuario);
    }
    console.log('terminó');

    return usuario;
}));
  }

  validate(){
    if(!this.currentUserValue){
      this.dialogConfig.data = {
        Titulo: 'Usuario no Encontrado!',
        Mensaje: 'No tiene permiso para ver esta información!',
        Salir: true
      };
      this.dialog.open(DialogComponent, this.dialogConfig);
    return false;
    }
    return true;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MovimientoHistoricoComponent } from './movimiento-historico/movimiento-historico.component';

@Component({
  selector: 'app-inventario',
  templateUrl: './inventario.component.html',
  styleUrls: ['./inventario.component.css']
})
export class InventarioComponent implements OnInit {
  @ViewChild(MovimientoHistoricoComponent) historico: MovimientoHistoricoComponent;
  constructor() { }

  ngOnInit() {
  }


  historicoClick(){
    this.historico.ngOnInit();
  }
}

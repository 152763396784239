
export class Usuario {
    Id: number;
    Nombre: string;
    RutUsuario: string;
    Email: string;
    Pass: string;
    NewPass: string;
    EsAdmin: boolean;
    Token: string;
    RefreshToken: string;
}

export class Categoria {
    Id: number;
    Nombre: string;
}

export class Marca {
    Id: number;
    Nombre: string;
}
export class Modelo {
    Id: number;
    Nombre: string;
    Marca: string;
    MarcaId: number; 
}
export class Producto {
    Id: number;
    Nombre: string;
    Anho: number;
    AnhoInicio: number;
    AnhoTermino: number;
    IdMarca: number;
    IdModelo: number;
    PrecioCompra: number;
    PrecioVenta: number;
    Cantidad: number; 
    isVenta: boolean;

    constructor(tipo: string = undefined) {
        this.Anho = 0;
        this.IdMarca = -1;
        this.IdModelo = -1;
        if (tipo === 'recepcion') {
            this.PrecioVenta = 0;
            this.PrecioCompra = 0;
        }
    }
}
export class Movimiento {
    Id: number;
    Fecha: string;
    Usuario: number;
    TipoMovimiento: string;
    TipoEgreso: string;
    Cliente: Cliente;
    Detalle: any[];

}
export class DetalleRecepcion {
    IdMovimiento: number;
    Producto: string;
    PrecioCompra: number;
    PrecioVenta: number;
    Cantidad: number; 
 
}
export class Stock {
    IdProducto: number;
    PrecioCompra: number;
    PrecioVenta: number; 
}
export class Egreso {
    IdMovimiento: number;
    Fecha: string;
    Usuario: number;
    TipoMovimiento: string;
    Tipo: string;
    Detalle: DetalleEgreso[];
    
}
export class DetalleEgreso {
    IdMovimiento: number;
    Producto: string;
    Destinatario: string;
    Pendiente: boolean;
    Cantidad: number; 
 
}
export class DetalleVenta {
    Codigo: number;
    Descripcion: string;
    Cantidad: number;
    Precio: number;
    DescuentoUnitario: number;
    Valor: number;
}
export class RecepcionPrestamo {
    Id: number;
    Fecha: string;
    IdMovimiento: number;
    IdProducto: number;
 
}

export class Cliente{
    Rut: string;
    NombreCompleto: string;
    Direccion: string;
    Mail: string;
    Fono: string;
}





import { Component, OnInit, ViewChild, ChangeDetectorRef, OnChanges } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialogConfig, MatDialog } from '@angular/material';
import { EgresoService } from '../../services/egreso.service';
import {  Producto, Movimiento } from 'src/app/global/model/Model';
import { AuthenticationService } from 'src/app/usuario/service/authentication.service';
import { first } from 'rxjs/operators';
import { BuscaProductoComponent } from '../../widget/busca-producto/busca-producto.component';
import { DialogComponent } from 'src/app/global/dialog/dialog.component';

@Component({
  selector: 'app-egreso',
  templateUrl: './egreso.component.html',
  styleUrls: ['./egreso.component.css']
})
export class EgresoComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort
  @ViewChild(BuscaProductoComponent) buscador : BuscaProductoComponent;
  ListaProducto: any[] = [];
  dataSource: MatTableDataSource<any>;
  isLoadingResults: boolean;
  resultsLength = 0;
  displayedColumns: string[];
  ProductoEdit: Producto;
  ProductoDelete: Producto;
  Tipo: string = '1';

  dialogConfig = new MatDialogConfig();

  constructor(private crd: ChangeDetectorRef,
    private auth: AuthenticationService,
    private egreso: EgresoService,
    private dialog: MatDialog) {
     
     }

  ngOnInit() {
    (<HTMLSelectElement>document.getElementById("selectTipoMovimiento")).value = '1';
    this.displayedColumns = ['Id', 'Producto', 'Modelo', 'Marca', 'AnhoInicio', 'AnhoTermino', 'Cantidad', 'Edit', 'Delete'];
  }

  ngOnChanges(){
    if(this.buscador.TipoMovimiento != undefined){
      
    switch(this.buscador.TipoMovimiento){
      case '1':{
        
        this.displayedColumns = ['Id', 'Producto', 'Modelo', 'Marca', 'AnhoInicio', 'AnhoTermino', 'Cantidad', 'Edit', 'Delete'];
        break;
      }
      case '2':{
        
        this.displayedColumns = ['Id', 'Producto', 'Modelo', 'Marca', 'AnhoInicio', 'AnhoTermino', 'Destinatario', 'Cantidad', 'Edit', 'Delete'];
      }
  }}
  this.dataSource = new MatTableDataSource(this.ListaProducto);
  this.crd.detectChanges();
  }



  onAdd(producto: any) {
    let existe = this.ListaProducto.findIndex(p => p.Id === producto.Id);
    
    if (existe > -1) {
      this.ListaProducto.splice(existe, 1);
    }

    this.ListaProducto.push(producto);
    this.dataSource = new MatTableDataSource(this.ListaProducto);
    this.isLoadingResults = false;
    this.crd.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.resultsLength = this.ListaProducto.length;
    this.isLoadingResults = false;


  }


  onSave(){
    let nueva = new Movimiento();
    nueva.Usuario = this.auth.currentUserValue.Id;
    nueva.Detalle = this.dataSource.data;
    nueva.TipoMovimiento = "Egreso";
    nueva.TipoEgreso = this.Tipo;

    console.log(nueva.Detalle);
    
    this.egreso.nuevo(nueva)
    .pipe(first())
        .subscribe(items => {
          this.dialogConfig.data = {
            Titulo: 'Yujuuu!',
            Mensaje: 'Los datos se guardaron correctamente.',
            Salir: false
          };
          this.dialog.open(DialogComponent, this.dialogConfig);
          
          // (<HTMLSelectElement>document.getElementById("selectModelo")).value = "-1";
        }, error =>{
          this.dialogConfig.data = {
            Titulo: 'Ups!',
            Mensaje: 'Ha ocurrido un problema al guardar este movimiento.',
            Salir: false
          };
          this.dialog.open(DialogComponent, this.dialogConfig);
        });

  } 

  onEdit(row: any){
    
    this.buscador.onEdit(row);
    console.log(this.ProductoEdit);
    
    this.ListaProducto.splice(this.ListaProducto.findIndex(p => p.Id === row.Id), 1);
    this.dataSource = new MatTableDataSource(this.ListaProducto);
    this.isLoadingResults = false;
    this.crd.detectChanges();
  }

  onDelete(row: any) {
    
    this.ListaProducto.splice(this.ListaProducto.findIndex(p => p.Id === row.Id), 1);
    this.dataSource = new MatTableDataSource(this.ListaProducto);
    this.isLoadingResults = false;
    this.crd.detectChanges();
  }


  onSelectTipoMovimiento(value: string){
    this.buscador.TipoMovimiento = value;
  this.ngOnChanges();    

  }


}

import { Component, OnInit, OnChanges } from '@angular/core';
import { AuthenticationService } from 'src/app/usuario/service/authentication.service';
import { Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { DialogComponent } from 'src/app/global/dialog/dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnChanges {
  Usuario: any;
  dialogConfig = new MatDialogConfig();

  constructor(private auth: AuthenticationService,
              private router: Router,
              public dialog: MatDialog) { }

  ngOnInit() {
    this.Usuario = this.auth.currentUserValue;
  }

  ngOnChanges(){
    this.Usuario = this.auth.currentUserValue;
  }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable, of, BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import { map, tap, catchError, switchMap, filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as Global from '../../global/global';
import { Usuario } from 'src/app/global/model/Model';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private auth: AuthenticationService,
              private http: HttpClient,
              private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(request)
    
    if (request.url.match(/rest\//)) {
      if (!request.url.includes('login.php')) {
        
        
        request = request.clone({
          setHeaders: {
            Authorization: `${this.auth.currentUserValue.Token}`,
            "Access-Control-Allow-Origin": "*"
          }
        });
        // console.log(request);
        
      }
      else {
        // request = request.clone({
        //   setHeaders: {
        //     Authorization: `Bearer `,
        //     "Access-Control-Allow-Origin": "*"
        //   }
        // });
      }
    }
    return next.handle(request).catch(err => {
      if (err.status === 409) {
              if(!this.isRefreshing){
                this.isRefreshing = true;
            let params = {
              token: this.auth.currentUserValue.Token,
              refreshToken: this.auth.currentUserValue.RefreshToken
            };
            return this.http.post<Usuario>(`${Global.apiUrl}login/refresh`, params).flatMap(
              (data) => {
                localStorage.setItem('currentUser', JSON.stringify(data));
                this.auth.currentUserSubject.next(data);
                  this.addToken(request, data.Token);
                  this.isRefreshing = false;

                  return next.handle(request).catch(err => {
                    //Catch another error
                    return of(null)
                  });
              }
            );
          }
          
      }
      return Observable.throw(err);
  });

    // return next.handle(request)
    //   .pipe(tap(
    //     (response: HttpEvent<any>) => {
    //       console.log(response); // this runs always 
    //       // not the same as "next?" parameter of .subscribe
    //     },
    //     (error: HttpErrorResponse) => {
    //       console.log(error); // this runs when you get error 
    //       // same as "error?" parameter of .subscribe
    //       if (error instanceof HttpErrorResponse && error.status === 401) {
    //         console.log('No Autorizado');
    //         this.handle401Error(request, next);
    //       }
    //     },
    //     () => {
    //       console.log("completed successfully"); // this runs when you don't get error
    //       // same as "complete?" parameter of .subscribe
    //     }
    //   ));

  }



  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      console.log('refreshing');

      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.auth.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.jwt);
          return next.handle(this.addToken(request, token.jwt));
        }));

    } else {
      console.log('else');
      
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
}
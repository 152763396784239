import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/global/model/Model';
import { HttpClient } from '@angular/common/http';
import { CryptoService } from 'src/app/global/Services/crypto.service';
import { AuthenticationService } from '../service/authentication.service';

import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/global/Services/shared.service';
import { DIR_DOCUMENT_FACTORY } from '@angular/cdk/bidi/typings/dir-document-token';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { DialogComponent } from 'src/app/global/dialog/dialog.component';

@Component({
  selector: 'app-recuperar-password',
  templateUrl: './recuperar-password.component.html',
  styleUrls: ['./recuperar-password.component.css']
})
export class RecuperarPasswordComponent implements OnInit {

  Login: Usuario;
  error = '';
  loading = false;
  Cargando: boolean;
  NewPass: string;
  dialogConfig = new MatDialogConfig();
  constructor(private http: HttpClient,
    private crypt: CryptoService,
    private router: Router,
    private auth: AuthenticationService,
    private shared: SharedService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.Login = new Usuario;
  }

  onChangePass() {
    this.Cargando = true;
    var isValid;
    let Validando = new Promise((resolve, reject) => { resolve(isValid = this.shared.Validarformulario(document)) });

    Promise.all([Validando]).then(() => {
      //console.log(isValid);
      if (isValid) {
        let login = new Usuario;
        login.Email = this.Login.Email;


        var promisePass = new Promise((resolve, reject) => { resolve(login.Pass = this.crypt.EncryptString(this.Login.Pass).toString()) });
        var promiseNewPass = new Promise((resolve, reject) => { resolve(login.NewPass = this.crypt.EncryptString(this.Login.NewPass).toString()) });

        Promise.all([promisePass, promiseNewPass])
          .then(result => {
            this.auth.cambiar(login)
              .pipe(first())
              .subscribe(
                data => {
                  this.dialogConfig.data = {
                    Titulo: 'Contraseña Actualizada!',
                    Mensaje: 'Se ha cambiado su contraseña.',
                    Salir: false
                  };
                  this.dialog.open(DialogComponent, this.dialogConfig);

                  this.router.navigate(['/usuario/login']);
                },
                error => {
                  this.dialogConfig.data = {
                    Titulo: 'No se pudo actualizar!',
                    Mensaje: 'Su usuario o contraseña actual son incorrectos!',
                    Salir: false
                  };
                  this.dialog.open(DialogComponent, this.dialogConfig);
                });
          });
      }
    })


  }

  validaContenido(value: string, tipo: string) {
    if (value.length > 0) {
      document.getElementById("txt" + tipo).classList.remove("is-invalid");
    } else {
      document.getElementById("txt" + tipo).classList.add("is-invalid");
    }
  }
  validaCoincidencia(value: string, tipo: string) {
    if (this.Login.NewPass === this.NewPass) {
      document.getElementById("txtNewPass").classList.remove("is-invalid");
      document.getElementById("txtConfirmNewPass").classList.remove("is-invalid");
    } else {
      document.getElementById("txtNewPass").classList.add("is-invalid");
      document.getElementById("txtConfirmNewPass").classList.add("is-invalid");
    }
  }


}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Global from '../../global/global';
import { map } from 'rxjs/operators';
import { Modelo } from 'src/app/global/model/Model';

@Injectable({
  providedIn: 'root'
})
export class ModeloService {

  constructor(private http:HttpClient) { }

  nuevo(item: any){
    return this.http.post<Modelo>(`${Global.apiUrl}modelo/create.php`, item, Global.httpPostOptions)
    .pipe(map(result =>{
        return result;
    }));
  }
  remove(item: any){
    return this.http.get<Modelo>(`${Global.apiUrl}modelo/delete.php/?id=` + item, Global.httpPostOptions)
      .pipe(map(result =>{
        return result;
    }));
  }
  update(item: any){
    return this.http.post<Modelo>(`${Global.apiUrl}modelo/update.php`, item, Global.httpPostOptions)
      .pipe(map(result =>{
        return result;
    }));
  }

  getAll(){
    return this.http.get<any>(`${Global.apiUrl }modelo/read.php`, Global.httpPostOptions)
    .pipe(map(result =>{
        return result;
    }))
  }
  getPorMarca(marca:number){
    return this.http.get<any>(`${Global.apiUrl }modelo/read-por-marca.php/?id=`+marca, Global.httpPostOptions)
    .pipe(map(result =>{
        return result;
    }))
  }
}

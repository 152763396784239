import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialogConfig, MatDialog } from '@angular/material';
import { RecepcionService } from '../../services/recepcion.service';
import { Movimiento, Producto } from 'src/app/global/model/Model';
import { AuthenticationService } from 'src/app/usuario/service/authentication.service';
import { first } from 'rxjs/operators';
import { BuscaProductoComponent } from '../../widget/busca-producto/busca-producto.component';
import { DialogComponent } from 'src/app/global/dialog/dialog.component';

@Component({
  selector: 'app-recepcion',
  templateUrl: './recepcion.component.html',
  styleUrls: ['./recepcion.component.css']
})
export class RecepcionComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort
  @ViewChild(BuscaProductoComponent) buscador : BuscaProductoComponent;
  ListaProducto: any[] = [];
  dataSource: MatTableDataSource<any>;
  isLoadingResults: boolean;
  resultsLength = 0;
  displayedColumns: string[];
  ProductoEdit: Producto;
  ProductoDelete: Producto;

  dialogConfig = new MatDialogConfig();
  Producto: any;


  constructor(private crd: ChangeDetectorRef,
              private auth: AuthenticationService,
              private recepcion: RecepcionService,
              private dialog: MatDialog) { 
    this.displayedColumns = ['Id', 'Producto', 'Modelo', 'Marca', 'AnhoInicio', 'AnhoTermino', 'PrecioCompra', 'PrecioVenta', 'Cantidad', 'Edit', 'Delete'];
  }

  ngOnInit() {
  }


  onAdd(producto: any) {
    let existe = this.ListaProducto.findIndex(p => p.Id === producto.Id);
    console.log(existe);
    
    if (existe > -1) {
      this.ListaProducto.splice(existe, 1);
    }

    this.ListaProducto.push(producto);
    this.dataSource = new MatTableDataSource(this.ListaProducto);
    this.isLoadingResults = false;
    this.crd.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.resultsLength = this.ListaProducto.length;
    this.isLoadingResults = false;


    console.log(this.dataSource.data);


  }

  onSave(){
    let nueva = new Movimiento();
    nueva.Usuario = this.auth.currentUserValue.Id;
    nueva.Detalle = this.dataSource.data;
    nueva.TipoMovimiento = "Recepcion";

    console.log(nueva);
    
    this.recepcion.nuevo(nueva)
    .pipe(first())
        .subscribe(items => {
          this.dialogConfig.data = {
            Titulo: 'Yujuuu!',
            Mensaje: 'Los datos se guardaron correctamente.',
            Salir: false
          };
          this.dialog.open(DialogComponent, this.dialogConfig);
          
          // (<HTMLSelectElement>document.getElementById("selectModelo")).value = "-1";
        }, error =>{
          this.dialogConfig.data = {
            Titulo: 'Ups!',
            Mensaje: 'Ha ocurrido un problema al guardar este Movimiento.',
            Salir: false
          };
          this.dialog.open(DialogComponent, this.dialogConfig);
        });

  }

  onEdit(row: any){
    
    this.buscador.onEdit(row);
    console.log(this.ProductoEdit);
    
    this.ListaProducto.splice(this.ListaProducto.findIndex(p => p.Id === row.Id), 1);
    this.dataSource = new MatTableDataSource(this.ListaProducto);
    this.isLoadingResults = false;
    this.crd.detectChanges();
  }

  onDelete(row: any) {
    
    this.ListaProducto.splice(this.ListaProducto.findIndex(p => p.Id === row.Id), 1);
    this.dataSource = new MatTableDataSource(this.ListaProducto);
    this.isLoadingResults = false;
    this.crd.detectChanges();
  }

}

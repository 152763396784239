import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Global from '../../global/global';
import { map } from 'rxjs/operators';
import { DetalleRecepcion } from 'src/app/global/model/Model';

@Injectable({
  providedIn: 'root'
})
export class DetalleRecepcionService {

  constructor(private http: HttpClient) { }

  nuevo(item: any){
    return this.http.post<DetalleRecepcion>(`${Global.apiUrl}detalle_recepcion/create.php`, item, Global.httpPostOptions)
    .pipe(map(result =>{
        return result;
    }));
  }
  remove(item: any){
    return this.http.get<DetalleRecepcion>(`${Global.apiUrl}detalle_recepcion/delete.php/?id=`+ item, Global.httpPostOptions)
      .pipe(map(result =>{
        return result;
    }));
  }
  update(item: any){
    return this.http.post<DetalleRecepcion>(`${Global.apiUrl}detalle_recepcion/update.php`, item, Global.httpPostOptions)
      .pipe(map(result =>{
        return result;
    }));
  }

  find(IdMovimiento: number){
    return this.http.get<any>(`${Global.apiUrl }detalle_recepcion/read.php/?id=` + IdMovimiento, Global.httpPostOptions)
    .pipe(map(result =>{
        return result;
    }))
  }
}

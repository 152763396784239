import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { DialogData } from '../global';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent {

  Titulo: string;
  Mensaje: string;
  Salir: boolean;
  constructor(public dialogRef: MatDialogRef<DialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private router: Router,
            ) {
                this.Titulo = data.Titulo;
                this.Mensaje = data.Mensaje;
                this.Salir = data.Salir;
               }
  close() {
    if(this.Salir)
    {
      this.router.navigate(['Home']);
      this.dialogRef.close();
      
    }
    else{
      this.dialogRef.close();
    }
  }

}

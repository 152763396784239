import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Global from '../../global/global';
import { map } from 'rxjs/operators';
import { DetalleEgreso } from 'src/app/global/model/Model';

@Injectable({
  providedIn: 'root'
})
export class DetalleEgresoService {
  Pendiente: DetalleEgreso;

  constructor(private http: HttpClient) { }

  find(IdMovimiento: number){
    return this.http.get<any>(`${Global.apiUrl }detalle_egreso/read.php/?id=` + IdMovimiento, Global.httpPostOptions)
    .pipe(map(result =>{
        return result;
    }))
  }

  update(registro: any){
    return this.http.post<DetalleEgreso>(`${Global.apiUrl}detalle_egreso/update.php`, registro, Global.httpPostOptions)
      .pipe(map(result =>{
        return result;
    }));
  }
}

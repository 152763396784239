import { Component, OnInit } from '@angular/core';
import { ClienteService } from '../../services/cliente.service';
import { Cliente } from 'src/app/global/model/Model';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css']
})
export class ClienteComponent implements OnInit {
  Cliente: Cliente = new Cliente();
  constructor(private cliente: ClienteService) { }

  ngOnInit() {
  }

  find(){
    this.cliente.find(this.Cliente)
    .pipe(first())
    .subscribe(cliente => {
      console.log(cliente);
      if(cliente != undefined){
        this.Cliente = cliente;}
        console.log(this.Cliente);
        
    });
  }

  nuevo(){
    this.cliente.nuevo(this.Cliente)
    .pipe(first())
    .subscribe(cliente => {
      this.Cliente = cliente;
    });
  }

}

import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
export class ExcelService {
constructor() { }
public exportAsExcelFile(json: any[], excelFileName: string): void {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFile(excelBuffer, excelFileName);
}
private saveAsExcelFile(buffer: any, fileName: string): void {
   const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
   FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
}

public saveAsFile(archivo: any): void {
  // require('es6-promise').polyfill();
  // require('isomorphic-fetch');
  //  let content = archivo.Contenido.split(';');
  //  //console.log(content[1])

  //  var file = new Blob([content[1]], { type: archivo.Tipo});
  //  var fileURL = URL.createObjectURL(file);
  //  window.open(fileURL);

  // fetch(archivo.Contenido)
  // .then(res => res.blob())
  // .then(blob => window.URL.createObjectURL(blob))
  // .then(url => window.open(url))

  // fetch(archivo.Contenido)
  // .then(res => res.blob())
  // .then(blob => FileSaver.saveAs(blob, archivo.Nombre))

    // const data: Blob = new Blob([archivo.Contenido], {type: archivo.Tipo});
    // FileSaver.saveAs(data, archivo.Nombre);

    var dataURI = archivo.Contenido;
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    let file = new Blob([ia], {type:mimeString});
    FileSaver.saveAs(file, archivo.Nombre);
}
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Global from '../../global/global';
import { map } from 'rxjs/operators';
import { RecepcionPrestamo } from 'src/app/global/model/Model';

@Injectable({
  providedIn: 'root'
})
export class RecepcionPrestamoService {

  constructor(private http: HttpClient) { }

  nuevo(item: RecepcionPrestamo){
    return this.http.post<RecepcionPrestamo>(`${Global.apiUrl}recepcion_prestamo/create.php`, item, Global.httpPostOptions)
    .pipe(map(result =>{
        return result;
    }));
  }

  find(item: RecepcionPrestamo){
    return this.http.post<any>(`${Global.apiUrl }recepcion_prestamo/read.php`, item, Global.httpPostOptions)
    .pipe(map(result =>{
        return result;
    }))
  }
}

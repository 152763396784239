import { Injectable } from '@angular/core';
import * as Global from '../global';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { forEach } from '@angular/router/src/utils/collection';
import { Calendar, CalendarModule } from 'primeng/calendar';

@Injectable({
  providedIn: 'root' 
})
export class SharedService {
  dialogConfig = new MatDialogConfig();
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  constructor(private http: HttpClient,
    public dialog: MatDialog) { }

  public Validarformulario(document: Document): boolean {
    var Elementos = document.getElementsByClassName('obligatorio')
    var i, isValid = true, count = 0;

    for (i = 0; i < Elementos.length; i++) {
      var campo = Elementos[i];
      if (campo.id.includes('txt')) {
        var input = (<HTMLInputElement>campo);

        if (input.value.trim() == '') {

          input.classList.add("is-invalid");
          count++;
        }
        else {
          input.classList.remove("is-invalid");
        }
      }
      if (campo.id.includes('select')) {
        var select = (<HTMLSelectElement>campo);
        if (select.value == '' || select.value.includes('Seleccione')) {
          select.classList.add("is-invalid");
          count++;
        } else {
          select.classList.remove("is-invalid");
        }
      }
      if (campo.id.includes('date')) {
        // var comps = campo.getElementsByClassName("ui-inputtext ui-widget ui-stat")
        // for(i = 0; i < comps.length; i++){
        //   //console.log(comps[i]);

        // }

      }


    }
    return count == 0;
  }

  public ValidaFormatoMail(mail: string) {
    //console.log(this.regexp.test(mail));
    //console.log(mail);

    return this.regexp.test(mail)
  }
}

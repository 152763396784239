import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Global from '../../global/global';
import { map } from 'rxjs/operators';
import { Cliente } from 'src/app/global/model/Model';
@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(private http: HttpClient) { }

  find(Cliente: Cliente){
    return this.http.post<any>(`${Global.apiUrl }cliente/find.php`, Cliente, Global.httpPostOptions)
    .pipe(map(result =>{
      console.log(result);
      
      if(result.Registros.length > 0){
        return result.Registros[0];
      }
      return undefined;
    }))
  }

  nuevo(Cliente: Cliente){
    return this.http.post<Cliente>(`${Global.apiUrl}cliente/create.php`, Cliente, Global.httpPostOptions)
    .pipe(map(result =>{
        return result;
    }));
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Global from '../../global/global';
import { map } from 'rxjs/operators';
import { DetalleVenta } from 'src/app/global/model/Model';
@Injectable({
  providedIn: 'root'
})
export class DetalleVentaService {

  constructor(private http: HttpClient) { }

  find(IdMovimiento: number){
    return this.http.get<any>(`${Global.apiUrl }detalle_venta/read.php/?id=` + IdMovimiento, Global.httpPostOptions)
    .pipe(map(result =>{
        return result;
    }))
  }
}

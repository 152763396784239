import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DetalleVenta } from 'src/app/global/model/Model';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { VentaService } from '../services/venta.service';
import { Movimiento, Producto } from 'src/app/global/model/Model';
import { AuthenticationService } from 'src/app/usuario/service/authentication.service';
import { first } from 'rxjs/operators';
import { DialogComponent } from 'src/app/global/dialog/dialog.component';
import { ClienteComponent } from '../widget/cliente/cliente.component';

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.css']
})
export class VentasComponent implements OnInit {
  @ViewChild(ClienteComponent) Cliente: ClienteComponent; 
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort
  ListaProducto : DetalleVenta[] = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['Codigo', 'Descripcion', 'Cantidad', 'Precio', 'DescuentoUnitario', 'Valor'];
  
  isLoadingResults: boolean;
  resultsLength: number;

  dialogConfig = new MatDialogConfig();
  constructor(private crd: ChangeDetectorRef,
              private auth: AuthenticationService,
              private venta: VentaService,
              private dialog: MatDialog
              ) {
    
    
   }

  ngOnInit() {
  }


  
  onAdd(producto: any) {
    let existe = this.ListaProducto.findIndex(p => p.Codigo === producto.Id);
    console.log(producto);
    
    if (existe > -1) {
      this.ListaProducto.splice(existe, 1);
    }

    let item = new DetalleVenta()
    item.Codigo = producto.Id;
    item.Descripcion = producto.Nombre + ' ' + producto.NombreMarca + ' ' + producto.NombreModelo + ' año ' + producto.AnhoInicio + '/' + producto.AnhoTermino;
    item.Cantidad = producto.Cantidad;
    item.Precio = producto.PrecioVenta;
    item.DescuentoUnitario = producto.DescuentoUnitario;
    item.Valor = producto.Cantidad * (producto.PrecioVenta - producto.DescuentoUnitario);

    this.ListaProducto.push(item);
    this.dataSource = new MatTableDataSource(this.ListaProducto);
    this.isLoadingResults = false;
    this.crd.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.resultsLength = this.ListaProducto.length;
    this.isLoadingResults = false;

  }

  getCostoTotal(){
    return this.ListaProducto.map(t => t.Valor).reduce((acc, value) => acc + value, 0);
  }

  onSave(){
  let nueva = new Movimiento();
  nueva.Usuario = this.auth.currentUserValue.Id;
  nueva.Detalle = this.dataSource.data;
  nueva.TipoMovimiento = "Venta";
  nueva.Cliente = this.Cliente.Cliente;
  console.log(nueva);
  
  this.venta.nuevo(nueva)
  .pipe(first())
      .subscribe(items => {
        this.dialogConfig.data = {
          Titulo: 'Yujuuu!',
          Mensaje: 'Los datos se guardaron correctamente.',
          Salir: false
        };
        this.dialog.open(DialogComponent, this.dialogConfig);
        
        // (<HTMLSelectElement>document.getElementById("selectModelo")).value = "-1";
      }, error =>{
        this.dialogConfig.data = {
          Titulo: 'Ups!',
          Mensaje: 'Ha ocurrido un problema al guardar este Movimiento.',
          Salir: false
        };
        this.dialog.open(DialogComponent, this.dialogConfig);
      });

}

}

import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import { longStackSupport } from 'q';
import { DateFormatPipe } from './date-format.pipe';
@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  Key: string;
  IV: string;

  constructor(public pipe: DateFormatPipe) {

    this.Key = CryptoJS.enc.Utf8.parse('7061737323313233');
    this.IV = CryptoJS.enc.Utf8.parse('7061737323313233');
  }

  public EncryptString(string: string) {
    return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(string), this.Key,
      {
        keySize: 128 / 8,
        iv: this.IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
  }
  public EncryptObjects(Objeto: any): any {

    for (var a in Objeto) {
// if(){
//   Objeto[a] = Objeto[a]
// }

      if (a == 'ArchivoAdjunto') {
        Objeto[a] = this.EncryptObjects(Objeto[a])
      }
      if (a == 'Asistentes' ||
        a == 'AsistentesAguas' ||
        a == 'AsistentesFFPP' ||
        a == 'OtrosAsistentes') {
        var lista = Objeto[a];
        Objeto[a] = []
        for (var asistente in lista) {
          Objeto[a].push(this.EncryptObjects(lista[asistente]));
        }
      }
      
      
      else {
        if (typeof Objeto[a] == 'string' ||
            a == 'MontoInvitacionAnterior' ||
            a == 'Fecha' ||
            a == 'FechaAnterior' ||
            a == 'OtroMotivo'
          ) {
          var algo = this.EncryptString(Objeto[a]);
          Objeto[a] = algo.toString()
        }
      }
    }
    return Objeto;
  }

  public DecryptObjects(Objeto): any {
    var res = new Object();
    for (var a in Objeto) {
      if (Objeto[a] == null) continue;
      if (a == 'Destinatario') continue;
      var d;
     
      if (a == 'FechaRegistro') {
        let fecha = Objeto[a].toString().substring(0, 10).split('-')
        res[a] = fecha[2] + '/' + fecha[1] + '/' + fecha[0];
        continue;
      }
      if( a == 'Fecha' ||
          a == 'FechaAnterior'){
          res[a] = this.pipe.transform(this.DecryptString(Objeto[a]).toString(CryptoJS.enc.Utf8)).toString();
          console.log(res[a]);
          console.log(res);
          
        }

      if (typeof Objeto[a] == 'string' 
      ) {
        d = this.DecryptString(Objeto[a]).toString(CryptoJS.enc.Utf8);
      }
      if (a == 'VistoJefatura' ||
        a == 'VistoCompliance' ||
        a == 'Gastos' ||
        a == 'Id' ||
        a == 'SemaforoJef' ||
        a == 'SemaforoCom' ||
        a == 'AutorizacionJefatura' ||
        a == 'AutorizacionCompliance'||
        a == 'DiasCompliance' ||
        a == 'DiasJefatura' ||
        a == 'FechaAutorizacionCompliance' ||
        a == 'FechaAutorizacionJefatura') {
        d = Objeto[a];

      }

      
      if (a == 'ArchivoAdjunto') {
        d = this.DecryptObjects(Objeto[a])
      }
      if (a == 'Asistentes' ||
        a == 'AsistentesAguas' ||
        a == 'AsistentesFFPP' ||
        a == 'OtrosAsistentes') {
        var lista = Objeto[a]
          Objeto[a] = []
            for (var asistente in lista) {
              Objeto[a].push(this.DecryptObjects(lista[asistente]))
            }
        d = Objeto[a]
      }

      if (a == 'Tipo' && Objeto[a].hasOwnProperty('Tipo')) {
        d = Objeto[a].Tipo;
      }

      res[a] = d;
    };
    return res;


  }
  public DecryptString(word: string) {
    return CryptoJS.AES.decrypt(word, this.Key, {
      keySize: 128 / 8,
      iv: this.IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })
  }

  public DecryptStringWord(word: string): string {
    return CryptoJS.AES.decrypt(word, this.Key, {
      keySize: 128 / 8,
      iv: this.IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
  }

 

  // public Prueba() {
  //   var key = CryptoJS.enc.Utf8.parse('7061737323313233');
  //   var iv = CryptoJS.enc.Utf8.parse('7061737323313233');
  //   var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse("It works"), key,
  //     {
  //       keySize: 128 / 8,
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7
  //     });

  //   var decrypted = CryptoJS.AES.decrypt('4fBtd3mEZ+fqMLOonHywkw==', key, {
  //     keySize: 128 / 8,
  //     iv: iv,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7
  //   });

  //   //console.log('Encrypted :' + encrypted);
  //   //console.log('Key :' + encrypted.key);
  //   //console.log('Salt :' + encrypted.salt);
  //   //console.log('iv :' + encrypted.iv);
  //   //console.log('Decrypted : ' + decrypted);
  //   //console.log('utf8 = ' + decrypted.toString(CryptoJS.enc.Utf8));
  // }
}

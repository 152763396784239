import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Global from '../../global/global';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoriaService {

  constructor(private http: HttpClient) { }

  getCategoria(){
    return this.http.get<any>(`${Global.apiUrl}categoria/read.php`)
      .pipe(map(categorias => {
              return categorias;
          }, error =>{
            console.log(error);
            
          }));
  }
}

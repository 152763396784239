import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/global/model/Model';
import { HttpClient } from '@angular/common/http';

import { UsuarioService } from '../service/usuario.service';
import { first } from 'rxjs/operators';
import { SharedService } from 'src/app/global/Services/shared.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogComponent } from 'src/app/global/dialog/dialog.component';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {
  Usuario: Usuario;
  dialogConfig = new MatDialogConfig();
  Cargando: boolean;
  RutValido: boolean;
  constructor(private http: HttpClient,
    private user: UsuarioService,
    private shared: SharedService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.Usuario = new Usuario();
  }

  Registrar() {
    this.Cargando = true;
    var isValid;
    let Validando = new Promise((resolve, reject) => { resolve(isValid = this.shared.Validarformulario(document)) });

    Promise.all([Validando]).then(() => {
      //console.log(isValid);
      if (isValid) {
        this.user.registrar(this.Usuario)
          .pipe(first())
          .subscribe(usuario => {
            this.dialogConfig.data = {
              Titulo: 'Usuario Registrado!',
              Mensaje: 'Se ha enviado un Mail con su Usuario y Clave. Podrá ver sus organizaciones cuando el administrador lo autorice.',
              Salir: false
            };
            this.dialog.open(DialogComponent, this.dialogConfig);
            this.Cargando = false;
          });
      } else {
        this.dialogConfig.data = {
          Titulo: 'Error!',
          Mensaje: 'Los campos resaltados son obligatorios',
          Salir: false
        };
        this.dialog.open(DialogComponent, this.dialogConfig);
        this.Cargando = false;
      }
    });
  }

  validaRut(rut: string, tipo: string){
         var valor = rut.replace('.','');
     valor = valor.replace('-','');
         let cuerpo = valor.slice(0,-1);
     let dv = valor.slice(-1).toUpperCase();
     switch(tipo){
       case 'Usuario':{
        this.Usuario.RutUsuario = cuerpo + '-'+ dv
         break;
       }
         case 'RazonSocial':{
          // this.Usuario.RutRazonSocial = cuerpo + '-'+ dv
           break;
       }
     }
         
         if(cuerpo.length < 7) { document.getElementById("txtRut"+tipo).classList.add("is-invalid"); return false;}
         let suma = 0;
     let multiplo = 2;
         for(var i=1;i<=cuerpo.length;i++) {
             let index = multiplo *  parseInt(valor.charAt(cuerpo.length - i));
         suma = suma + index;
    
         if(multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }
   
     }
         let dvEsperado = (11 - (suma % 11)).toString();
         dv = (dv == 'K') ? '10':dv;
     dv = (dv == '0') ? '11': dv;
             // Validar que el Cuerpo coincide con su Dígito Verificador
     if(dvEsperado != dv) {
        document.getElementById("txtRut"+tipo).classList.add("is-invalid");
        this.RutValido = false;
        return false; 
      }
      this.RutValido = true;
      document.getElementById("txtRut"+tipo).classList.remove("is-invalid");
     
  }

  filterChars(event) {
    var k = event.charCode;
    return ((k > 47 && k < 58) || k === 107 || k == 75);
  }

}

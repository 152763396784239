import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Global from '../../global/global';
import { map } from 'rxjs/operators';
import { Marca } from 'src/app/global/model/Model';

@Injectable({
  providedIn: 'root'
})
export class MarcaService {

  constructor(private http: HttpClient) { }

  nuevo(item: any){
    return this.http.post<Marca>(`${Global.apiUrl}marca/create.php`, item, Global.httpPostOptions)
    .pipe(map(result =>{
        return result;
    }));
  }
  remove(item: any){
    return this.http.get<Marca>(`${Global.apiUrl}marca/delete.php/?id=`+ item, Global.httpPostOptions)
      .pipe(map(result =>{
        return result;
    }));
  }
  update(item: any){
    return this.http.post<Marca>(`${Global.apiUrl}marca/update.php`, item, Global.httpPostOptions)
      .pipe(map(result =>{
        return result;
    }));
  }

  getAll(){
    return this.http.get<any>(`${Global.apiUrl }marca/read.php`, Global.httpPostOptions)
    .pipe(map(result =>{
        return result;
    }))
  }
}

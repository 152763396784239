import { Component, OnInit, OnChanges } from '@angular/core';
import { AuthenticationService } from '../usuario/service/authentication.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit, OnChanges {
  Usuario: any;
  
  
  constructor(private auth: AuthenticationService) { }

  ngOnInit() {
    this.auth.validate();
  }

  ngOnChanges(){
    this.auth.validate();
  }

}

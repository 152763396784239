import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return super.transform(value, "dd/MM/yyyy");
  }
  getYear(value: any, args?: any): any {
    return super.transform(value, "yyyy");
  }
  getMonth(value: any, args?: any): any {
    return super.transform(value, "M");
  }
  fechaSAP(value: any, args?: any): any {
    return super.transform(value, "yyyy-MM-dd");
  }
}

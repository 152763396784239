import { Component, OnInit } from "@angular/core"

@Component ({
    selector: "home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
    imagen: string;

    constructor() {}
    ngOnInit() {
        
    }
    mostrarImagen(e:HTMLImageElement){
        console.log(e.src);
        this.imagen = e.src;
        // e.src
        // document.getElementById("modal-body").innerHTML=e
    }
}





import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import * as Global from '../../global/global';
import { map } from 'rxjs/operators';
import { Usuario } from 'src/app/global/model/Model';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http: HttpClient) { }

  getPorEnlazar(){
    return this.http.get<any>(`${Global.apiUrl}usuario/por-enlazar`)
      .pipe(map(usuarios => {
              return usuarios;
          }, error =>{
            console.log(error);
            
          }));
  }

  getEnlazados(){
    return this.http.get<any>(`${Global.apiUrl}usuario/enlazados`)
      .pipe(map(usuarios => {
              return usuarios;
          }, error=> {
            console.log(error);
            
          }));
  }

  registrar(usuario: Usuario){
    return this.http.post<Usuario>(`${Global.apiUrl}usuario/nuevo`, usuario, Global.httpPostOptions )
    .pipe(map(usuario => {
      return usuario;
    }))
  }

  enlazar(idUsuario: number, RutEmpresa: string ){
    return this.http.post<boolean>(`${Global.apiUrl}usuario/enlazar`, {idUsuario, RutEmpresa})
    .pipe(map(data => {
      return true;
    }, error =>{
      console.log(error);
      
      return false;
    }))
  }

  enlazadosPorEmpresa(rutEmpresa:string){
    return this.http.get<any>(`${Global.apiUrl}usuario/enlazados/`+rutEmpresa)
    .pipe(map(data => {
      return data;
    }, error =>{
      console.log(error);
      
    }))
  }

  cargaMasiva(json: any){
    return this.http.post<boolean>(`${Global.apiUrl}usuario/carga-masiva`, json, Global.httpPostOptions)
    .pipe(map(data => {
      return true;
    }, error =>{
      console.log(error);
      
      return false;
    }))
  }

  infoMasiva(json: any){
    return this.http.post<boolean>(`${Global.apiUrl}usuario/info-masiva`, json, Global.httpPostOptions)
    .pipe(map(data => {
      return true;
    }, error =>{
      console.log(error);
      
      return false;
    }))
  }
}

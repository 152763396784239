import { Component, OnInit, ViewChild, ChangeDetectorRef, OnChanges } from '@angular/core';
import { Categoria, Marca, Modelo, Producto } from 'src/app/global/model/Model';
import { MatTableDataSource, MatPaginator, MatSort, MatDialogConfig, MatDialog } from '@angular/material';
import { CategoriaService } from '../services/categoria.service';
import { first } from 'rxjs/operators';
import { MarcaService } from '../services/marca.service';
import { ModeloService } from '../services/modelo.service';
import { ProductoService } from '../services/producto.service';
import { DialogComponent } from 'src/app/global/dialog/dialog.component';
import { AuthenticationService } from 'src/app/usuario/service/authentication.service';
@Component({
  selector: 'app-mantenedor',
  templateUrl: './mantenedor.component.html',
  styleUrls: ['./mantenedor.component.css']
})
export class MantenedorComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort

  ListaCategorias: Categoria[] = [];
  ListaMarca: Marca[] = [];
  ListaModelo: Modelo[] = [];
  Marca = new Marca;
  Modelo = new Modelo;
  Categoria = new Categoria;
  Producto = new Producto
  NuevoItem: any = new Object();
  RemoveItem: any = new Object();
  dataSource: MatTableDataSource<any>;

  ListaItem: any[] = [];
  displayedColumns: string[];
  isLoadingResults: boolean;
  resultsLength = 0;

  dialogConfig = new MatDialogConfig();
  UpdateItem: Marca;
  constructor(private auth: AuthenticationService,
              private categorias: CategoriaService,
              private marca: MarcaService,
              private modelo: ModeloService,
              private producto: ProductoService,
              private crd: ChangeDetectorRef,
              public dialog: MatDialog) {

    this.displayedColumns = [];

  }

  ngOnInit() {
    if (this.auth.validate()) {
      this.getCategorias()
    }
  }


  getCategorias() {
    this.categorias.getCategoria()
      .pipe(first())
      .subscribe(categorias => {
        this.ListaCategorias = categorias.Registros;
        this.Categoria = this.ListaCategorias.find(c => (c.Id) === 1)
        this.getItems(this.Categoria.Nombre);
      });
  }

  onSave() {
    switch (this.Categoria.Nombre) {
      case 'Marca': {
        if (this.NuevoItem.Id != undefined) {
          this.marca.update(this.NuevoItem)
            .pipe(first())
            .subscribe(item => {
              this.NuevoItem = item
              this.getItems(this.Categoria.Nombre);
              //Mensaje de registro Ok
            });
        } else {
          this.marca.nuevo(this.NuevoItem)
            .pipe(first())
            .subscribe(item => {
              this.NuevoItem = item
              this.getItems(this.Categoria.Nombre);
              //Mensaje de registro Ok
            });
        }
        break;
      }
      case 'Modelo': {
        if (this.NuevoItem.Id != undefined) {
          this.modelo.update(this.NuevoItem)
            .pipe(first())
            .subscribe(item => {
              this.NuevoItem = item;
              (<HTMLSelectElement>document.getElementById("selectMarca")).value = "-1";
              this.getItems(this.Categoria.Nombre);
              //Mensaje de registro Ok
            });
        } else {        
        this.modelo.nuevo(this.NuevoItem)
          .pipe(first())
          .subscribe(item => {
            this.NuevoItem = item;
            (<HTMLSelectElement>document.getElementById("selectMarca")).value = "-1";
            
            this.getItems(this.Categoria.Nombre);
            //Mensaje de registro Ok
          });
      }
        break;
      }
      case 'Producto': {
        if (this.NuevoItem.Id != undefined) {
          this.producto.update(this.NuevoItem)
            .pipe(first())
            .subscribe(item => {
              this.NuevoItem = item;
              (<HTMLSelectElement>document.getElementById("selectMarca")).value = "-1";
              this.getItems(this.Categoria.Nombre);
              //Mensaje de registro Ok
            });
        } else { 
        this.producto.nuevo(this.NuevoItem)
          .pipe(first())
          .subscribe(item => {
            this.NuevoItem = item;
            (<HTMLSelectElement>document.getElementById("selectMarca")).value = "-1";
            this.getItems(this.Categoria.Nombre);
          });
      }
        break;
      }
    }

  }

  onDelete(id: number) {
    switch (this.Categoria.Nombre) {
      case 'Marca': {

        this.marca.remove(id)
          .pipe(first())
          .subscribe(item => {
            this.RemoveItem = item
            this.getItems(this.Categoria.Nombre);
            //Mensaje de registro Ok
          });
        break;
      }
      case 'Modelo': {

        this.modelo.remove(id)
          .pipe(first())
          .subscribe(item => {
            this.RemoveItem = item
            this.getItems(this.Categoria.Nombre);
            //Mensaje de registro Ok
          });
        break;
      }
      case 'Producto': {

        this.producto.remove(id)
          .pipe(first())
          .subscribe(item => {
            this.RemoveItem = item
            this.getItems(this.Categoria.Nombre);
            //Mensaje de registro Ok
          });
        break;
      }
    }
  }
  onEdit(row: any) {
    switch (this.Categoria.Nombre) {
      case 'Marca': {
        this.NuevoItem.Id = row.Id;
        this.NuevoItem.Nombre = row.NombreMarca;
        break;
      }
      case 'Modelo': {
        this.NuevoItem.Id = row.Id;
        this.NuevoItem.Nombre = row.NombreModelo;
        this.NuevoItem.Marca = row.Marca;
        (<HTMLSelectElement>document.getElementById("selectMarca")).value = row.Marca;
        break;
      }
      case 'Producto': {
        this.NuevoItem.Id = row.Id;
        this.NuevoItem.Nombre = row.NombreProducto;
        this.NuevoItem.AnhoInicio = row.AnhoInicio;
        this.NuevoItem.AnhoTermino = row.AnhoTermino;
        // this.NuevoItem.IdModelo = row.IdModelo;
        (<HTMLSelectElement>document.getElementById("selectMarca")).value = row.Marca;
        
        let promise = new Promise((resolve, reject) => { resolve(this.onMarcaChange(row.Marca))});
        
        Promise.all([promise]).then(() => {
          setTimeout(() => 
          {
              (<HTMLSelectElement>document.getElementById("selectModelo")).value = row.Modelo;
              this.NuevoItem.Modelo = row.Modelo
              this.NuevoItem.ModeloAnterior = row.Modelo
          },
          50);
        });
        break;
      }
    }
  }

  getItems(categoria: string) {
    switch (categoria) {
      case 'Marca': {
        this.marca.getAll()
          .pipe(first())
          .subscribe(items => {
            this.dataSource = new MatTableDataSource(items.Registros);
            this.isLoadingResults = false;
            this.crd.detectChanges();
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.resultsLength = items.Registros.length;
            this.isLoadingResults = false;
            this.displayedColumns = ['Id', 'Marca', 'Edit', 'Delete'];

            if (items.Registros.length === 0) {
              this.dialogConfig.data = {
                Titulo: 'No se encontraron registros!',
                Mensaje: 'No hay marcas para mostrar!',
                Salir: false
              };
              this.dialog.open(DialogComponent, this.dialogConfig);
            }

            this.ListaMarca = items.Registros;
            
            
          });
        break;
      }
      case 'Modelo': {
        this.modelo.getAll()
          .pipe(first())
          .subscribe(items => {
            this.dataSource = new MatTableDataSource(items.Registros);
            this.isLoadingResults = false;
            this.crd.detectChanges();
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.resultsLength = items.Registros.length;
            this.isLoadingResults = false;
            this.displayedColumns = ['Id', 'Modelo', 'Marca', 'Edit', 'Delete'];

            if (items.Registros.length === 0) {
              this.dialogConfig.data = {
                Titulo: 'No se encontraron registros!',
                Mensaje: 'No hay modelos para mostrar!',
                Salir: false
              };
              this.dialog.open(DialogComponent, this.dialogConfig);
            }
          });
        break;
      }
      case 'Producto': {
        this.producto.getAll()
          .pipe(first())
          .subscribe(items => {
            this.dataSource = new MatTableDataSource(items.Registros);
            this.isLoadingResults = false;
            this.crd.detectChanges();
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.resultsLength = items.Registros.length;
            this.isLoadingResults = false;
            this.displayedColumns = ['Id', 'Producto', 'Modelo', 'Marca', 'AnhoInicio', 'AnhoTermino', 'Edit', 'Delete'];

            if (items.Registros.length === 0) {
              this.dialogConfig.data = {
                Titulo: 'No se encontraron registros!',
                Mensaje: 'No hay productos para mostrar!',
                Salir: false
              };
              this.dialog.open(DialogComponent, this.dialogConfig);
            }
            (<HTMLSelectElement>document.getElementById("selectMarca")).value = "-1";
            (<HTMLSelectElement>document.getElementById("selectModelo")).value = "-1";
          });
        break;
      }
    }
  }



  onMarcaChange(id: number, modelo:number = 0) : any{
    this.NuevoItem.Marca = id;
    if (this.Categoria.Nombre === 'Producto') {

      this.modelo.getPorMarca(id)
        .pipe(first())
        .subscribe(items => {
          if (items.Registros.length === 0) {
            this.dialogConfig.data = {
              Titulo: 'No se encontraron registros!',
              Mensaje: 'No hay modelos para mostrar!',
              Salir: false
            };
            this.dialog.open(DialogComponent, this.dialogConfig);
          }else{
            
          }
          this.ListaModelo = items.Registros;
         
          
          // (<HTMLSelectElement>document.getElementById("selectModelo")).value = "-1";
        });
    }
    return true;
  }

  onModeloChange(id: number) {
    this.NuevoItem.Modelo = id;
  }

  onProductoChange() {
    this.NuevoItem.Producto = this.Producto.Id;

  }

  applyFilter(filterValue: string) {
    if (this.dataSource != undefined) {
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }
}

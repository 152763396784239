import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Global from '../../global/global';
import { map } from 'rxjs/operators';
import { Producto } from 'src/app/global/model/Model';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {

  constructor(private http: HttpClient) { }

  nuevo(item: any){
    return this.http.post<Producto>(`${Global.apiUrl}producto/create.php`, item, Global.httpPostOptions)
    .pipe(map(result =>{
        return result;
    }));
  }
  remove(item: any){
    return this.http.get<Producto>(`${Global.apiUrl}producto/delete.php/?id=`+ item, Global.httpPostOptions)
      .pipe(map(result =>{
        return result;
    }));
  }
  update(item: any){
    return this.http.post<Producto>(`${Global.apiUrl}producto/update.php`, item, Global.httpPostOptions)
      .pipe(map(result =>{
        return result;
    }));
  }

  getAll(){
    return this.http.get<any>(`${Global.apiUrl }producto/read.php`, Global.httpPostOptions)
      .pipe(map(result =>{
        return result;
      }))
  }
  find(item: Producto){
    return this.http.post<any>(`${Global.apiUrl }producto/find.php`, item, Global.httpPostOptions)
      .pipe(map(result =>{
        return result;
      }))
  }

}

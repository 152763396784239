import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, OnChanges } from '@angular/core';
import { first } from 'rxjs/operators';
import { MatTableDataSource, MatPaginator, MatSort, MatDialogConfig, MatDialog } from '@angular/material';
import { DetalleRecepcion, Producto, DetalleEgreso, RecepcionPrestamo } from 'src/app/global/model/Model';
import { DialogComponent } from 'src/app/global/dialog/dialog.component';
import { DetalleEgresoService } from '../../services/detalle-egreso.service';
import { RecepcionPrestamoService } from '../../services/recepcion-prestamo.service';
import { AuthenticationService } from 'src/app/usuario/service/authentication.service';


@Component({
  selector: 'app-detalle-movimiento',
  templateUrl: './detalle-movimiento.component.html',
  styleUrls: ['./detalle-movimiento.component.css'],

})
export class DetalleMovimientoComponent implements OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() DetalleMovimiento: any;
  @Input() TipoEgreso: string;

  Producto: any;
  dataSource: MatTableDataSource<any>;
  isLoadingResults: boolean;
  resultsLength;
  ListaDetalleMovimiento: any[];
  IdMovimiento: number;
  displayedColumns = ['Producto', 'Modelo', 'Marca', 'AnhoInicio', 'AnhoTermino', 'Destinatario', 'Cantidad', 'Pendiente'];
  DetalleEgreso = new DetalleEgreso;
  item: string;
  NuevoPendiente: number;
  Pendiente: any;
  dialogConfig = new MatDialogConfig();

  constructor(private crd: ChangeDetectorRef,
              private dialog: MatDialog,
              private detalleEgreso: DetalleEgresoService,
              private recPrestamo: RecepcionPrestamoService,
              private auth: AuthenticationService

  ) { }

  ngOnChanges() {


    if (this.DetalleMovimiento != undefined && this.DetalleMovimiento.length > 0) {

      if (this.TipoEgreso.includes('Préstamo')) {
        this.displayedColumns = ['Producto', 'Modelo', 'Marca', 'AnhoInicio', 'AnhoTermino', 'Destinatario', 'Cantidad', 'Pendiente'];
      }
      if (this.TipoEgreso.includes('Merma')) {
        this.displayedColumns = ['Producto', 'Modelo', 'Marca', 'AnhoInicio', 'AnhoTermino', 'Cantidad'];
      }
      if (this.TipoEgreso == ' - Venta') {
        this.displayedColumns = ['Codigo', 'Descripcion', 'Cantidad', 'Precio', 'DescuentoUnitario', 'Valor'];
      }

      

      this.ListaDetalleMovimiento = this.DetalleMovimiento;
      this.dataSource = new MatTableDataSource(this.ListaDetalleMovimiento);
      this.isLoadingResults = false;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.resultsLength = this.ListaDetalleMovimiento.length;
      this.isLoadingResults = false;

      this.IdMovimiento = this.ListaDetalleMovimiento[0].IdMovimiento
    }
  }

  recepcionPrestamo(row) {
    if (row.Pendiente == '1') {

      let registro: any = new Object();
      registro.IdMovimiento = this.IdMovimiento;
      registro.Producto = row.IdProducto;
      registro.Usuario = this.auth.currentUserValue.Id;
      this.detalleEgreso.update(registro)
        .pipe(first())
        .subscribe(NuevoPendiente => {
          let nuevaLista: any[];
          nuevaLista = [];
          var detalle: any;
          for (let item in this.dataSource.data) {
            detalle = this.dataSource.data[item];
            if (detalle.Producto === row.Producto) {
              detalle.Pendiente = '0';
            }
            let rec: RecepcionPrestamo = new RecepcionPrestamo();
            rec.IdMovimiento = detalle.IdMovimiento;
            rec.IdProducto = detalle.IdProducto;
            this.recPrestamo.find(rec)
            .pipe(first())
            .subscribe(res =>{
              detalle.Recepcion = res.Registros[0];
              nuevaLista.push(detalle);
              this.dataSource = new MatTableDataSource(nuevaLista);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.isLoadingResults = false;
              console.log(nuevaLista);
            })
          }
          
          
        }, error => {
          this.dialogConfig.data = {
            Titulo: 'Ups!',
            Mensaje: 'Ha ocurrido un problema al guardar la recepción de este préstamo.',
            Salir: false
          };
          this.dialog.open(DialogComponent, this.dialogConfig);
        });

    }
    if (row.Pendiente == '0') {
      // Mostrar la recepción del préstamo
      this.dialogConfig.data = {
        Titulo: 'Préstamo recepcionado por: Administrador Autoglass',
        Mensaje: 'El usuario activo en el momento recibió el préstamo de este producto',
        Salir: false
      };
      this.dialog.open(DialogComponent, this.dialogConfig);
    }
  }

  getCostoTotal(){
    return this.ListaDetalleMovimiento.map(t => t.Valor).reduce((acc, value) => acc + value, 0);
  }
}
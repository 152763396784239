import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './/app-routing.module';
import { CalendarModule } from 'primeng/calendar'
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material';
import { NgxEditorModule } from 'ngx-editor';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ExcelService } from './global/Services/excel.service';
import { CryptoService} from './global/Services/crypto.service';


// Common
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home/home.component';
import { HeaderComponent } from './home/header/header.component';
import { FooterComponent } from './home/footer/footer.component';
import { ProgressComponent } from './global/progress/progress.component';
import { DialogComponent } from './global/dialog/dialog.component';
import { getSpanishPaginatorIntl, customCurrencyMaskConfig } from './global/global';
import { DateFormatPipe } from './global/Services/date-format.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

//  Usuario 
import { LoginComponent } from './usuario/login/login.component';
import { RegistroComponent } from './usuario/registro/registro.component';
import { RecuperarPasswordComponent } from './usuario/recuperar-password/recuperar-password.component';
import { TokenInterceptor } from './usuario/service/token.interceptor';
 
import { RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { InventarioComponent } from './admin/inventario/inventario.component';
import { MantenedorComponent } from './admin/mantenedor/mantenedor.component';
import { VentasComponent } from './admin/ventas/ventas.component';
import { BuscaProductoComponent } from './admin/widget/busca-producto/busca-producto.component';
import { RecepcionComponent } from './admin/inventario/recepcion/recepcion.component';
import { EgresoComponent } from './admin/inventario/egreso/egreso.component';

import { NgxCurrencyModule } from 'ngx-currency';
import { MovimientoHistoricoComponent } from './admin/inventario/movimiento-historico/movimiento-historico.component';
import { DetalleMovimientoComponent } from './admin/inventario/detalle-movimiento/detalle-movimiento.component';
import { ClienteComponent } from './admin/widget/cliente/cliente.component'

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    RegistroComponent,
    RecuperarPasswordComponent,
    ProgressComponent,
    DialogComponent,
    DateFormatPipe,
    AdminComponent,
    InventarioComponent,
    MantenedorComponent,
    VentasComponent,
    BuscaProductoComponent,
    RecepcionComponent,
    EgresoComponent,
    MovimientoHistoricoComponent,
    DetalleMovimientoComponent,
    ClienteComponent,

    
  ],
  imports: [
    BrowserModule,
    MatDialogModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CalendarModule,
    NgxEditorModule,
    MatAutocompleteModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    TooltipModule.forRoot()
    

  ],
  providers: [AppComponent, 
              {provide: LocationStrategy, useClass: HashLocationStrategy},
              {provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl()},
              ExcelService,
              CryptoService,
              DateFormatPipe,
              {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true}
            ],
            
  bootstrap: [AppComponent],
  entryComponents: [DialogComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { first } from 'rxjs/operators';

import { Usuario } from 'src/app/global/model/Model';
import { AuthenticationService } from 'src/app/usuario/service/authentication.service';
import { CryptoService } from 'src/app/global/Services/crypto.service';
import { SharedService } from 'src/app/global/Services/shared.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogComponent } from 'src/app/global/dialog/dialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loading = false;
  Logged: boolean = false;
  Login: Usuario;
  error = '';
  returnUrl: string;
  Cargando: boolean;
  dialogConfig = new MatDialogConfig();
  constructor(private http: HttpClient,
    private router: Router,
    private crypt: CryptoService,
    private auth: AuthenticationService,
    private shared: SharedService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.Login = new Usuario();
  }

  onLogin() {
    this.Cargando = true;
    var isValid;
    let Validando = new Promise((resolve, reject) => { resolve(isValid = this.shared.Validarformulario(document)) });

    Promise.all([Validando]).then(() => {
      //console.log(isValid);
      if (isValid) {
    let login: any = new Object;
    login.email = this.Login.Email;

    var promise = new Promise((resolve, reject) => { resolve(login.password = this.crypt.EncryptString(this.Login.Pass).toString()) });

    Promise.all([promise])
      .then(result => {

        this.auth.login(login)
          .pipe(first())
          .subscribe(
            user => {
                this.router.navigate(['administrador']);
              
            },
            error => {
              console.log(error);
              
              this.dialogConfig.data = {
                Titulo: 'No se puede iniciar Sesión!',
                Mensaje: 'El Mail o la Contraseña ingresados son incorrectos!',
                Salir: false
              };
              this.dialog.open(DialogComponent, this.dialogConfig);
            });
      });
    }})
  }
}

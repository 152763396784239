import { MatPaginatorIntl } from '@angular/material';
import { HttpHeaders } from '@angular/common/http';



//API Localhost
// export const apiUrl = 'http://localhost:8001/rest/'
export const apiUrl = 'https://www.autoglassmys.cl/rest/'


//  export const apiUrl = 'http://localhost/Autoglass/rest/'
//export const apiUrl = 'http://localhost:4202/proveedores/rest/api/';
//API PROD
//export const apiUrl='https://apps.essal.cl/proveedores/rest/api/';

export const httpPostOptions =
{
  headers:
    new HttpHeaders(
      {
        'Content-Type': 'application/json'
      }),
  withCredentials: true,
};

export interface DialogData {
  Titulo: string;
  Mensaje: string;
  Salir: boolean;
  Boton: string;
}

const SpanishRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) { return `0 de ${length}`; }
  
    length = Math.max(length, 0);
  
    const startIndex = page * pageSize;
  
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
  
    return `${startIndex + 1} - ${endIndex} de ${length}`;
}

export function getSpanishPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();
  
    paginatorIntl.itemsPerPageLabel = 'Registros por página:';
    paginatorIntl.nextPageLabel = 'Página siguiente';
    paginatorIntl.previousPageLabel = 'Página Anterior';
    paginatorIntl.getRangeLabel = SpanishRangeLabel;
    paginatorIntl.firstPageLabel = 'Al Principio';
    paginatorIntl.lastPageLabel = 'Al Final';
  
    return paginatorIntl;
  }

  export const es = {

    closeText: 'Cerrar', 
    prevText: 'Anterior', 
    nextText: 'Siguiente', 
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'], 
    monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'], 
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'], 
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'], 
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'], 
    weekHeader: 'Semana', 
    firstDay: 1, 
    firstDayOfWeek: 1, 
    isRTL: false, 
    showMonthAfterYear: false, 
    yearSuffix: '', 
    timeOnlyTitle: 'Sólo hora', 
    timeText: 'Tiempo', 
    hourText: 'Hora', 
    minuteText: 'Minuto', 
    secondText: 'Segundo', 
    currentText: 'Fecha actual', 
    ampm: false, 
    month: 'Mes', 
    week: 'Semana', 
    day: 'Día', 
    allDayText: 'Todo el día'
};

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: false,
  decimal: "",
  precision: 0,
  prefix: "",
  suffix: "",
  thousands: ".",
  nullable: false
};
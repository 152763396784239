import { Component, OnInit, ViewChild, ChangeDetectorRef, OnChanges, Input } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { RecepcionService } from '../../services/recepcion.service';
import { EgresoService } from '../../services/egreso.service';
import { DetalleRecepcionService } from '../../services/detalle-recepcion.service';
import { Movimiento, RecepcionPrestamo, DetalleVenta } from 'src/app/global/model/Model';
import { first } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DetalleMovimientoComponent } from '../detalle-movimiento/detalle-movimiento.component';
import { BuscaProductoComponent } from '../../widget/busca-producto/busca-producto.component';
import { DetalleEgresoService } from '../../services/detalle-egreso.service';
import { RecepcionPrestamoService } from '../../services/recepcion-prestamo.service';
import { DetalleVentaService } from '../../services/detalle-venta.service';


@Component({
  selector: 'app-movimiento-historico',
  templateUrl: './movimiento-historico.component.html',
  styleUrls: ['./movimiento-historico.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MovimientoHistoricoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort
  @ViewChild(DetalleMovimientoComponent) Detalle: DetalleMovimientoComponent;
  @ViewChild(BuscaProductoComponent) buscador : BuscaProductoComponent;
  @Input() parent:string;
  ListaProducto: any[] = [];
  dataSource: MatTableDataSource<any>;
  isLoadingResults: boolean;
  resultsLength = 0;
  ListaMovimiento: Movimiento[] = [];
  displayedColumns = ['IdMovimiento', 'TipoMovimiento', 'Usuario', 'FechaMovimiento',  'VerDetalle'];
  Recepcion: Movimiento;
  IdMovimiento: number;
  DetalleMovimiento: any; 
  Expandir: boolean = false;
  TipoMovimiento: string;
  ListaEgreso: Movimiento[] = [];
  Egreso: Movimiento;
  TipoEgreso: string;


  

  constructor(private crd: ChangeDetectorRef,
              private recepcion: RecepcionService,
              private egreso: EgresoService,
              private detalleRecepcion: DetalleRecepcionService,
              private detalleEgreso: DetalleEgresoService,
              private detalleVenta: DetalleVentaService,
              private recPrestamo: RecepcionPrestamoService) { }

  ngOnInit() {
    this.recepcion.getAll()
    .pipe(first())
      .subscribe(recepcion => {
        this.ListaMovimiento = recepcion.Registros;
        this.Recepcion = this.ListaMovimiento.find(c => (c.Id) === 1)
        this.dataSource = new MatTableDataSource(this.ListaMovimiento);
        this.isLoadingResults = false;
        this.crd.detectChanges();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.resultsLength = this.ListaMovimiento.length;
        this.isLoadingResults = false;
        
      });
}

  read(IdMovimiento: number){
    
    let movimiento = this.ListaMovimiento.find(m => m.Id === IdMovimiento);
console.log(movimiento);

    switch(movimiento.TipoMovimiento){
      case  'Egreso':{
        this.detalleEgreso.find(IdMovimiento)
        .pipe(first())
          .subscribe(detalleMovimiento => {
            this.DetalleMovimiento = [];
            
            this.TipoEgreso = " - " + movimiento.TipoEgreso;
            detalleMovimiento.Registros.forEach(element => {
              let rec: RecepcionPrestamo = new RecepcionPrestamo();
              rec.IdMovimiento = element.IdMovimiento;
              rec.IdProducto = element.IdProducto;
              this.recPrestamo.find(rec)
              .pipe(first())
              .subscribe(res =>{
                element.Recepcion = res.Registros[0];
              })  
              this.DetalleMovimiento.push(element);
            });
          });
        break;
      }
      case  'Recepcion':{
        this.detalleRecepcion.find(IdMovimiento)
        .pipe(first())
          .subscribe(detalleMovimiento => {
            this.DetalleMovimiento = detalleMovimiento.Registros;
            this.TipoEgreso = "";
          });
        break;
      }
      case 'Venta':{
        this.detalleVenta.find(IdMovimiento)
        .pipe(first())
          .subscribe(detalleMovimiento => {
            this.DetalleMovimiento = detalleMovimiento.Registros;
            this.TipoEgreso = " - Venta";
          });
      }
    }
  }

}
